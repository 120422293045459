// <path id="Path_1271" data-name="Path 1271" d="M147,587.75c63.43,52.56,120.4,50.75,193.32,120.94S594.18,803,650.39,683.8s91.53-124.31,126.18-142.22c42.62-22.05,141.31-125-51.55-187.6S538.75,217,523.28,171.27,432.25-25.33,267,72.55s-93.41,195.73-97.58,259.17S59,514.86,147,587.75Z" transform="translate(-0.03 -11.14)" fill="#37cff1" />
// <path id="Path_1270" data-name="Path 1270" d="M193.14,238.82c-54.9,53.42-69.16,136.76-33,204.28,25.78,48.11,76.18,88.19,162.58,93.64,172.78,10.89,150.54,5.2,186.56,76.82s121,116.82,201,46c100.12-88.53-11.73-197.9-80.44-227.53s-57.71-123.47-35.34-176c57.29-134.44-53.89-259.73-241.78-138C287.66,160.26,231.44,201.56,193.14,238.82Z" transform="translate(-0.03 -11.14)" opacity="0.59" fill="url(#linear-gradient)" />
// <circle id="Ellipse_357" data-name="Ellipse 357" cx="837.24" cy="339.54" r="21.99" fill="#712b90" />
// <circle id="Ellipse_358" data-name="Ellipse 358" cx="667.47" cy="715.35" r="9.49" fill="#37cff1" />

function convertCircleToPath(cx, cy, r) {
    return 'M ' + (cx - r) + ', ' + (cy) + ' a ' + (r) + ', ' + (r) + ' 0 1, 0 ' + (r * 2) + ', 0 a ' + (r) + ', ' + (r) + ' 0 1, 0 -' + (r * 2) + ', 0';
}

export const circlesArray = [
    {
        "id": "blob12",
        "type": "circle", "fill": "url(#weaveGradient)",
        "d": convertCircleToPath(999, 539, 35)
    },
    {
        "id": "blob21",
        "type": "circle", "fill": "url(#weaveGradient1)",
        "d": convertCircleToPath(440, 550, 30)
    },
    {
        "id": "blob22",
        "type": "circle", "fill": "url(#weaveGradient)",
        "d": convertCircleToPath(500, 520, 140)
    },
    {
        "id": "blob7",
        "type": "circle", "fill": "url(#weaveGradient1)",
        "d": convertCircleToPath(600, 590, 40)
    },
    {
        "id": "blob8",
        "type": "circle", "fill": "url(#weaveGradient)",
        "d": convertCircleToPath(980, 190, 70)
    },
    {//top left medium pink circle
        "id": "blob9",
        "type": "circle", "fill": "url(#weaveGradient1)",
        "d": convertCircleToPath(620, 210, 110)
    },
    {//middle left medium blue circle
        "id": "blob10",
        "type": "circle", "fill": "url(#weaveGradient)",
        "d": convertCircleToPath(770, 450, 95)
    },
    {
        "id": "blob14",
        "type": "circle", "fill": "url(#weaveGradient1)",
        "d": convertCircleToPath(527, 339, 22)
    },
    {//middle left small blue circle
        "id": "blob15",
        "type": "circle", "fill": "url(#weaveGradient)",
        "d": convertCircleToPath(657, 339, 55)
    },
    { //bottom left small circle
        "id": "blob16",
        "type": "circle", "fill": "url(#weaveGradient1)",
        "d": convertCircleToPath(837, 555, 12)
    },
    // {
    //     "id": "path1",
    //     "type": "path",
    //     "d": "M147,587.75c63.43,52.56,120.4,50.75,193.32,120.94S594.18,803,650.39,683.8s91.53-124.31,126.18-142.22c42.62-22.05,141.31-125-51.55-187.6S538.75,217,523.28,171.27,432.25-25.33,267,72.55s-93.41,195.73-97.58,259.17S59,514.86,147,587.75Z",
    // },
    {
        "id": "blob11",
        "fill": "url(#weaveGradient)",
        "d": "M147,587.75c63.43,52.756,120.4,50.75,193.752,120.94S594.18,803,650.759,683.8s91.753-124.751,126.18-142.22c42.62-22.05,141.751-125-51.755-187.6S538.75,217,523.28,171.27,432.25-25.753,267,72.755s-93.41,195.73-97.758,259.17S59,514.86,147,587.75Z"
    },

    {
        "id": "blob1",
        "fill": "url(#weaveGradient1)",
        "type": "path",
        "d": "M344.615,191.879c3.699,14.279-10.945,19.409-33.184,18.025c-22.24-0.936-36.891-6.401-33.191-18.57c3.67-12.088, 18.553-22.051, 33.185-22.621C326.058, 167.541, 340.946, 177.677, 344.615, 191.879z",
    },
    {
        "id": "blob2",
        "fill": "url(#weaveGradient)",
        "type": "path",
        "d": "M326.792,199.809c0.96,3.645-2.84,8.864-8.606,10.044c-5.766,1.064-9.561-3.989-8.602-9.547c0.951-5.577, 4.812-9.615, 8.606-8.853C321.982, 192.371, 325.84, 196.142, 326.792, 199.809z",
    },

    {
        "id": "blob4",
        "fill": "url(#weaveGradient)",
        "type": "path",
        "d": "M319.333,172.231c2.007,7.745-5.936,10.527-17.998,9.776c-12.062-0.508-20.009-3.472-18.002-10.072c1.99-6.556, 10.063-11.96, 17.999-12.269C309.269, 159.031, 317.343, 164.528, 319.333, 172.231z",
    },

    {
        "id": "blob6",
        "fill": "url(#weaveGradient)",
        "type": "path",
        "d": "M302.333,162.763c0.905,4.505-2.51,8.59-7.666,8.211c-5.157-0.442-8.572-4.602-7.667-8.404c0.903-3.813, 4.337-7.225, 7.666-7.57C297.996, 154.739, 301.431, 158.248, 302.333, 162.763z",
    }
];
