import React from "react"
import { gsap, TweenMax, TimelineMax, Linear } from "gsap"
import styles from "./blobs.module.css"
import { circlesArray } from "./circles.js"

class Blobs extends React.Component {
  constructor(props) {
    super(props);
    this.myElements = [];
  }




  componentDidMount() {
    //gsap.to(this.myElements, randomBetween(10, 70), { autoAlpha: 1, delay: 0, duration: 1, rotation: randomBetween(-21, -360), x: randomBetween(100, 500), y: randomBetween(5, 15), stagger: randomBetween(0, .275), ease: "none", repeat: -1, yoyo: true, repeatDelay: 0 });
    var tl = new TimelineMax();

    //see https://codepen.io/CamillePx/pen/gMgOdK
    this.myElements.forEach(e => {
      var t = gsap.to(e, randomBetween(7, 75), {
        y: randomBetween(113, -775),
        x: randomBetween(121, 550),
        //rotation: randomBetween(21, 360),
        repeat: -1,
        repeatDelay: randomBetween(1, 3),
        yoyo: true,
        ease: Linear.easeNone
      })

      tl.add(t)
    });
    // for (var i = 0; i < 22; i++) {

    //   var t = gsap.to(document.querySelector('#blob' + i), randomBetween(7, 75), {
    //     y: randomBetween(3, 75),
    //     x: randomBetween(21, 550),
    //     rotation: randomBetween(21, 360),
    //     repeat: -1,
    //     repeatDelay: randomBetween(1, 3),
    //     yoyo: true,
    //     ease: Linear.easeNone
    //   })

    //   tl.add(t, (i + 1) / 0.6)
    // }


    tl.seek(100);
    tl.timeScale(1.5);

    function randomBetween(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  }


  render() {
    return <div className={styles[`loading_cont`]}>

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="loader">
        <defs>
          <linearGradient id="MyGradient">
            <stop offset="5%" stopColor="#ed1690" stopOpacity=".95" />
            <stop offset="40%" stopColor="#684b9f" stopOpacity=".95" />
            <stop offset="80%" stopColor="#47c7eb" stopOpacity=".95" />
          </linearGradient>
          <radialGradient id="bgGrad" cx="296.81" cy="281.6667" r="286.3365" gradientTransform="matrix(0.995 0 0 1 0 -3)" gradientUnits="userSpaceOnUse">
            <stop offset="5%" stopColor="#ed1690" stopOpacity=".95" />
            <stop offset="40%" stopColor="#684b9f" stopOpacity=".95" />
            <stop offset="80%" stopColor="#47c7eb" stopOpacity=".95" />
          </radialGradient>
          <radialGradient id="weaveGradient1" cx="318.1876" cy="204.6715" r="9.8593" gradientTransform="matrix(4.500007e-011 1 -1.3293 5.981685e-011 590.2494 -113.5161)" gradientUnits="userSpaceOnUse">
            <stop offset="1" stopColor="#00cdf0" stopOpacity="1" />
            <stop offset=".9" stopColor="#03c8ed" stopOpacity="1" />
            <stop offset=".81" stopColor="#0cbbe6" stopOpacity="1" />
            <stop offset=".65" stopColor="#1ca5d9" stopOpacity="1" />
            <stop offset=".48" stopColor="#3287c8" stopOpacity="1" />
            <stop offset=".31" stopColor="#4f60b2" stopOpacity="1" />
            <stop offset=".23" stopColor="#713198" stopOpacity="1" />
            <stop offset=".17" stopColor="#7c2390" stopOpacity="1" />
            <stop offset="0" stopColor="#ed1690" stopOpacity="1" />
          </radialGradient>
          <linearGradient id="weaveGradient" x1="141.26" y1="819.2" x2="142.43" y2="820.17" gradientTransform="matrix(-392.74, -521.18, -432.72, 326.08, 410808.9, -193020.7)" gradientUnits="userSpaceOnUse">
            <stop offset="3%" stopColor="#00cdf0" stopOpacity=".75" />
            <stop offset="9%" stopColor="#03c8ed" stopOpacity=".75" />
            <stop offset="21%" stopColor="#0cbbe6" stopOpacity=".75" />
            <stop offset="35%" stopColor="#1ca5d9" stopOpacity=".75" />
            <stop offset="48%" stopColor="#3287c8" stopOpacity=".75" />
            <stop offset="61%" stopColor="#4f60b2" stopOpacity=".75" />
            <stop offset="73%" stopColor="#713198" stopOpacity=".75" />
            <stop offset="87%" stopColor="#7c2390" stopOpacity=".75" />
            <stop offset="95%" stopColor="#ed1690" stopOpacity=".75" />
          </linearGradient>

          <clipPath id="mainBlobMask">
            <path id="blobShape" d="M147,587.75c63.43,52.756,120.4,50.75,193.752,120.94S594.18,803,650.759,683.8s91.753-124.751,126.18-142.22c42.62-22.05,141.751-125-51.755-187.6S538.75,217,523.28,171.27,432.25-25.753,267,72.755s-93.41,195.73-97.758,259.17S59,514.86,147,587.75Z" transform="translate(-50 -20.14)" fill="url(#SVGID_4_)" opacity="1" />

          </clipPath>

          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -9" result="cm" />
          </filter>
        </defs>

        <use href="#blobShape" fill="url(#bgGrad)" opacity="1" />


        <g filter="url(#goo)">
          {circlesArray.map((element, index) => <path
            key={element.id}
            id={element.id}
            d={element.d}
            fill={element.fill}
            transform={element.transform ? element.transform : "rotate(0) translate(0,0) rotate(0)"}
            ref={circle => this.myElements[index] = circle}
          />)}
          <path id="Path_1270"
            data-name="Path 1270"
            d="M678.8,148.9c-67.764-46.836-124.359-40.071-203.118-103.632S214.527-26.584,168.929,97.083,88.58,228.9,55.625,249.767C15.09,275.439-74.26,386.56,123.33,432.159S320.825,552.372,340.218,596.588s107.825,187.918,263.963,76,76-203.118,74.616-266.679S772.756,213.841,678.8,148.9Z"
            opacity="1"
            fill="url(#weaveGradient)"
            transform="translate(-30 -211.14)"
          />
          <path
            id="mainBlob"
            fill="url(#MyGradient)"
            d="M193.14, 238.82c - 54.9, 53.42-69.16, 136.76-33, 204.28, 25.78, 48.11, 76.18, 88.19, 162.758, 93.64,172.78, 10.89, 150.754, 5.2, 186.756, 76.82s121, 116.82, 201, 46c100.12-88.753 - 11.73 - 197.9 - 80.44 - 227.753s- 57.71 - 123.47 - 35.754 - 176c57.29 - 134.44 - 53.89 - 259.73 - 241.78 - 138C287.66,160.26, 231.44, 201.756, 193.14, 238.82Z"
            transform="translate(-50 -11.14)"
            opacity="1"
          />
        </g>
      </svg >
    </div >
  }
}

export default Blobs;
